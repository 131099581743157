<template>
  <div class="context-picker-modal">
    <b-modal
        id="modal-create-context"
        ref="modal-create-context"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createContext.title') }}</h3>
      </template>

      <template #default>
        <div class="m-4">
          <div v-if="availableCombinations.length === 0" class="text-center p-4">
            {{ $t('modals.createContext.noActions') }}
            <br>
            <b-button
                class="green-button-transparent border-0 mt-3"
                @click="$emit('cancel-modal')">
              {{ $t('buttons.close') }}
            </b-button>
          </div>
          <div v-else>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.operation') }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-group v-slot="{ ariaDescribedbyAction }">
                  <b-form-radio
                      v-for="actionObject in availableActions"
                      v-model="action"
                      class="mb-1"
                      :key="actionObject.value"
                      :aria-describedby="ariaDescribedbyAction"
                      name="action"
                      :disabled="actionObject.disabled"
                      :value="actionObject.value">{{ actionObject.text }}
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.value') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div>
                  <b-form-group v-slot="{ ariaDescribedbyValue }">
                    <b-form-radio
                        v-for="option in availableSources"
                        :key="option.value"
                        v-model="source"
                        class="text-uppercase"
                        :class="{ 'mb-1': !option.isLast }"
                        :aria-describedby="ariaDescribedbyValue"
                        name="source"
                        :value="option.value"
                        :disabled="option.disabled">
                      {{ $t(`modals.createContext.source.${option.translationKey}`) }}
                    </b-form-radio>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <b-button
            v-if="availableCombinations.length > 0"
            class="p-3 border-right-light-grey"
            block
            @click="$emit('cancel-modal')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            v-if="availableCombinations.length > 0"
            variant="primary"
            class="p-3"
            block
            :disabled="disableCreateButton"
            @click="createEntry"
        >
          {{ $t('buttons.create') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalCreateNewContext',
  props: {
    allActions: {
      type: Array,
    },
    sourceOptions: {
      type: Array,
    },
    availableCombinations: {
      type: Array,
    },
  },
  data() {
    return {
      action: null,
      source: null,
    };
  },
  created() {
    if (this.availableCombinations.length > 0) {
      this.action = this.availableCombinations[0].action;
      this.source = this.availableCombinations[0].source;
    }
  },
  mounted() {
    this.$refs['modal-create-context'].show();
  },
  computed: {
    disableCreateButton() {
      return !this.action || !this.source;
    },
    availableActions() {
      return this.allActions.map((action) => {
        const isDisabled = !this.availableCombinations.some((combination) => combination.action === action.value);
        return { ...action, disabled: isDisabled };
      });
    },
    availableSources() {
      return this.sourceOptions.map((source) => {
        const action = this.action || this.availableCombinations[0].action;
        const isDisabled = !this.availableCombinations.some((combination) => combination.source === source.value && combination.action === action);
        return { ...source, disabled: isDisabled };
      });
    },
  },
  methods: {
    createEntry() {
      this.$emit('create-context', {
        action: this.action,
        source: this.source,
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .modal-dialog {
  .modal-content {
    border: none;
    label {
      color: #6c757d;
    }
  }

  .custom-control-input ~ .custom-control-label {
    color: #6c757d;
  }
  .custom-control-input[disabled] ~ .custom-control-label {
    cursor: not-allowed;
    color: #bbb;
  }
  button:disabled {
    cursor: not-allowed;
  }
}
</style>
